@charset "UTF-8";
@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
.DefaultFont {
  font-family: "Noto Sans KR", sans-serif;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 300;
}

button,
a,
input[type=button],
input[type=submit] {
  cursor: pointer;
}

#root {
  height: 100%;
  position: relative;
}

/* section 초반 틀을 위해 position absolute */
section {
  min-height: 100%;
  position: relative;
}

.content-inseide {
  padding-bottom: 500px;
}
@media screen and (max-width: 1000px) {
  .content-inseide .content-inseide {
    padding-bottom: 580px;
  }
}

.loginPad {
  padding-bottom: 300px;
}
@media screen and (max-width: 1000px) {
  .loginPad .content-inseide {
    padding-bottom: 580px;
  }
}

#fullpage .wrap {
  width: 1200PX !important;
}
@media screen and (min-width: 1025px) and (max-width: 1199px) {
  #fullpage .wrap {
    width: 1025px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #fullpage .wrap {
    width: 90% !important;
  }
}
@media screen and (max-width: 767px) {
  #fullpage .wrap {
    width: 90% !important;
  }
}

.wrap {
  width: 1320px;
  margin: 0 auto;
}
@media screen and (min-width: 1199px) and (max-width: 1399px) {
  .wrap {
    width: 1199px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .wrap {
    width: 1025px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wrap {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .wrap {
    width: 90%;
  }
}

button,
input[type=button] {
  cursor: pointer;
}

.background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

body::-webkit-scrollbar {
  width: 5px;
  /*스크롤바의 너비*/
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(29, 23, 23, 0.3);
  /*스크롤바의 색상*/
  background-clip: padding-box;
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  /*스크롤바 트랙 색상*/
  box-shadow: inset 0px 0px 2px white;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.center {
  text-align: center !important;
}

.toastify.red {
  background: #FF4042 !important;
}
.toastify.green {
  background: #00c9b7 !important;
}
.toastify.blue {
  background: #86ABF3 !important;
}
.toastify.orange {
  background: #FFA56E !important;
}