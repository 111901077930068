.inner_section {
  padding-bottom: 110px;
}
.inner_section .img-responsive {
  width: 100%;
}

.last_section {
  padding-bottom: 190px;
}

h3 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 25px;
  color: #060d79;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  margin-bottom: 25px;
  font-size: 20px;
  color: #222222;
  font-weight: 500;
  line-height: 150%;
}
@media screen and (max-width: 767px) {
  h4 {
    font-size: 18px;
  }
}

h5 {
  text-align: center;
  margin-bottom: 55px;
  font-size: 16px;
  color: #666666;
  font-weight: 300;
  line-height: 150%;
}
@media screen and (max-width: 767px) {
  h5 {
    font-size: 14px;
  }
}

h6 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 20px;
  color: #418bd6;
  font-weight: 500;
  line-height: 150%;
}
@media screen and (max-width: 767px) {
  h6 {
    font-size: 18px;
  }
}

.center_img {
  margin: 30px auto;
}
@media screen and (max-width: 767px) {
  .center_img {
    width: 100%;
  }
}

.voucher_icon ul {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .voucher_icon ul {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .voucher_icon ul {
    grid-template-columns: 1fr;
  }
}

.voucher_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .voucher_grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .voucher_grid {
    grid-template-columns: 1fr;
  }
}

.voucher_cont {
  height: 100%;
  border-radius: 10px;
  background: #f1f5fa;
  text-align: center;
  padding: 20px 0;
}
@media screen and (max-width: 767px) {
  .voucher_cont {
    height: inherit !important;
    padding: 20px;
  }
}

.voucher_icon li img {
  display: inline-block;
}

.voucher_icon li p {
  font-size: 18px;
  color: #022a4b;
  padding: 15px 0;
  font-weight: 500;
}

.voucher_icon li span {
  font-size: 16px;
  line-height: 150%;
}
@media screen and (max-width: 767px) {
  .voucher_icon li span {
    font-size: 14px;
  }
}

.img_box {
  overflow: hidden;
}

.img_box p {
  padding-top: 55px;
}

.voucher_list01 ul {
  padding: 55px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .voucher_list01 ul {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .voucher_list01 ul {
    grid-template-columns: 1fr;
  }
}

.voucher_list01_wrap {
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 100%;
}
.voucher_list01_wrap .tit {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background: #54b2ff;
  padding: 24px 0;
  border-radius: 10px 10px 0 0;
}
@media screen and (max-width: 767px) {
  .voucher_list01_wrap .tit {
    font-size: 16px;
  }
}
.voucher_list01_wrap .cont {
  padding: 20px;
}

.voucher_list02 ul {
  padding-bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .voucher_list02 ul {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .voucher_list02 ul {
    grid-template-columns: 1fr;
  }
}

.voucher_list02_wrap {
  border: 1px solid #022a4b;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.voucher_list02_wrap .tit {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background: #022a4b;
  padding: 24px 0;
}

.voucher_list02_wrap .cont {
  padding: 23px 30px;
}
@media screen and (max-width: 767px) {
  .voucher_list02_wrap .cont {
    padding: 10px;
  }
}

.voucher_list02_wrap .cont p {
  padding-left: 10px;
  margin-bottom: 7px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .voucher_list02_wrap .cont p {
    font-size: 14px;
  }
}

.voucher_list02_wrap .cont p:after {
  background: #003ff2;
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  left: 0;
  top: 50%;
  margin-top: -2px;
}

.voucher_list02.bigtrade_list .cont p {
  margin-bottom: 10px;
  color: #000;
  font-weight: 500;
  line-height: 130%;
  font-size: 15px;
}

.voucher_list02.bigtrade_list .cont span {
  margin-bottom: 10px;
  display: block;
  line-height: 130%;
  font-size: 15px;
  font-weight: 300;
  color: #666;
}

.voucher_box_list {
  padding-bottom: 55px;
}
.voucher_box_list .grid {
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .voucher_box_list .grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .voucher_box_list .grid {
    grid-template-columns: 1fr;
  }
}

.voucher_box {
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voucher_box_title {
  position: absolute;
  left: 50%;
  top: -25px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  width: 252px;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;
  margin-left: -126px;
}

.voucher_box.box1 .voucher_box_title {
  background-color: #2ca6f4;
}
.voucher_box.box2 .voucher_box_title {
  background-color: #2ca6f4;
}
.voucher_box img {
  padding: 40px 0;
}