.splunk ._typing-title {
  font-size: 34px !important;
  line-height: 54px !important;
}
@media screen and (max-width: 767px) {
  .splunk ._typing-title {
    font-size: 22px !important;
  }
}
.splunk ._typing-title.title-margin {
  margin: 40px 0 20px;
  font-weight: 500;
}
.splunk ._typing-title.title-margin.first {
  margin-top: 100px;
}
.splunk ._typing-title .big {
  font-size: 40px;
}
@media screen and (max-width: 767px) {
  .splunk ._typing-title .big {
    font-size: 25px;
  }
}
.splunk .esonImage img {
  width: 100%;
}
.splunk ._typing-text {
  font-size: 22px !important;
  line-height: 38px !important;
  max-width: 900px;
  margin: 50px auto !important;
}
@media screen and (max-width: 767px) {
  .splunk ._typing-text {
    width: 80%;
  }
  .splunk ._typing-text br {
    display: none;
  }
}
.splunk ._typing-content {
  font-size: 18px !important;
  line-height: 30px !important;
  max-width: 900px;
  margin: 20px auto !important;
}
@media screen and (max-width: 767px) {
  .splunk ._typing-content {
    width: 80%;
  }
}

.Link {
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
  text-align: center;
  line-height: 30px;
}
.Link span {
  color: #1d9be2;
}