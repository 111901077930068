@charset "UTF-8";
.modal_wrap {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  transition: all 300ms ease-in-out;
}
.modal_wrap .modal_body {
  margin: auto;
  z-index: 9999;
  padding: 15px;
  border-radius: 8px;
  background-color: white;
}
.modal_wrap .modal_header {
  display: flex;
  padding: 0 15px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.modal_wrap .modal_header h3 {
  margin: 0 15px;
}
.modal_wrap .modal_header button {
  background-color: #e7e7e7;
  color: #000000;
  width: 25px;
  height: 25px;
  font-size: 1em;
  line-height: 40px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
}
.modal_wrap .modal_header button::before {
  content: "✖";
}
.modal_wrap .modal_header button:hover {
  transform: rotate(180deg);
}
.modal_wrap .modal_content {
  margin-top: 20px;
  padding-top: 5px;
  height: 100%;
  border-top: 2px solid #666666;
}
.modal_wrap .modal_content .inner_section {
  padding: 20px;
  max-height: calc(100% - 116px);
  overflow: auto;
}
.modal_wrap .modal_content .inner_section h4:first-child {
  margin: 0;
}
.modal_wrap .modal_content .inner_section h4 {
  margin-bottom: 25px;
  font-size: 18px;
  color: #222222;
  font-weight: 500;
  line-height: 150%;
}
.modal_wrap .modal_content .inner_section h5 {
  margin-bottom: 25px;
  font-size: 15px;
  color: #666666;
  font-weight: 300;
  line-height: 150%;
}

.on {
  opacity: 1;
  z-index: 10;
}