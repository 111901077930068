@charset "UTF-8";
.header_wrap .notosanscjkkr {
  color: #ffffff;
  font-size: max(min(1.3vw, 1.3em), 1rem);
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.header_wrap ul,
.header_wrap li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.header_wrap .header {
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 9;
  position: fixed;
  overflow: hidden;
  transition: all 200ms ease-in;
  border-bottom: 1px solid #e5e5e5;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: saturate(200%) blur(1.875rem);
}
.header_wrap .header p {
  margin: 0;
}
.header_wrap .header.hover {
  height: 450px;
  background-color: rgba(0, 0, 0, 0.65);
}
.header_wrap .header.hover .header_contents_wrap {
  border-bottom: 1px solid #e5e5e5;
}
.header_wrap .header .header_contents_wrap {
  height: 90px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 90px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  transition: all 300ms ease-in;
}
.header_wrap .header .logo_wrap {
  cursor: pointer;
  height: 27px;
  object-fit: cover;
  width: 100px;
}
.header_wrap .header .menu_wrap {
  display: grid;
  align-items: center;
  text-align: center;
}
.header_wrap .header .menu_wrap * {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}
.header_wrap .header .menu_wrap article {
  display: flex;
  position: relative;
  justify-content: center;
}
.header_wrap .header .menu_wrap article:hover ul::before {
  left: 0;
  width: 100%;
}
.header_wrap .header .menu_wrap article span:hover {
  scale: 1.1;
}
.header_wrap .header .menu_wrap article ul li:hover {
  color: #1d9be2;
}
.header_wrap .header .menu_wrap article ul::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -2px;
  width: 0;
  height: 3px;
  background-color: #1d9be2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 10;
}
.header_wrap .header .contact_btn {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  border-radius: 20px;
  background-image: linear-gradient(rgb(29, 155, 226) 0, rgb(8, 115, 175) 100%);
  transition: all 300ms ease-in-out;
}
.header_wrap .header .contact_btn:hover {
  scale: 1.05;
}
.header_wrap .header .btn_text {
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
}
.header_wrap .header .right_content_wrap {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header_wrap .header .lines_wrap {
  justify-content: center;
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-left: 20px;
  margin-top: 1px;
  min-height: 23px;
  width: 28px;
}
.header_wrap .header .lines_wrap:hover > div {
  animation: flashing 1s infinite alternate;
}
.header_wrap .header .lines_wrap:hover > div:nth-child(2) {
  animation-delay: 400ms;
}
.header_wrap .header .lines_wrap:hover > div:nth-child(3) {
  animation-delay: 800ms;
}
.header_wrap .header .rectangle {
  background-color: #ffffff;
  border: 1px none;
  border-radius: 5px;
  height: 3px;
  width: 28px;
}
@keyframes flashing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.header_wrap .header .gnb {
  top: 59px;
  width: 100%;
  height: 370px;
  display: none;
  position: absolute;
  border-left: 1px solid #e5e5e5;
}
.header_wrap .header .gnb:last-child {
  border-right: 1px solid #e5e5e5;
}
.header_wrap .header .gnb ul {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header_wrap .header .gnb li {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  letter-spacing: 0.2px;
  width: 100%;
  color: #ffffff;
}
.header_wrap .header .gnb li a {
  font-size: max(min(0.9vw, 0.9em), 1rem);
}
.header_wrap .header .gnb li:first-child {
  margin-top: 20px;
}
.header_wrap .header .gnb li:hover a {
  color: #1d9be2;
}
.header_wrap .header .shown {
  display: flex;
}
.header_wrap .header .responsive_gnb {
  display: none;
  grid-column: auto/span 2;
}
.header_wrap .header .responsive_gnb .gnb_page_title {
  overflow: hidden;
  font-size: 15px;
}
.header_wrap .header .responsive_gnb .gnb_page_title a {
  text-decoration: none;
  color: #fff;
}
.header_wrap .header .responsive_gnb .gnb_page_title:first-child {
  margin-top: 20px;
}
.header_wrap .header .responsive_gnb .gnb_page_title:hover a {
  color: #1d9be2;
}
.header_wrap .header .responsive_gnb .gnb_page_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.header_wrap .header .responsive_gnb .gnb_page_list li {
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;
}
.header_wrap .header .responsive_gnb .gnb_page_list li:hover a {
  color: #1d9be2;
}
.header_wrap .header .responsive_gnb .gnb_page_list li a {
  color: #ffffff;
  text-decoration: none;
}
.header_wrap .expand {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}
.header_wrap .expand .header_contents_wrap {
  border-bottom: 1px solid #e5e5e5;
}
.header_wrap .site_map_container {
  display: table;
  position: absolute;
  width: 0;
  height: 100vh;
  font-size: 0;
  top: 0;
  left: 100%;
  overflow-y: hidden;
  z-index: 10001;
  background: #000;
  transition: all 0.6s ease;
}
.header_wrap .site_map_container .site_map_content > section {
  width: 0;
}
.header_wrap .open {
  left: 0;
  width: 100%;
  position: fixed;
}
.header_wrap .open .site_map_content {
  opacity: 1;
}
.header_wrap .open .site_map_content section {
  width: 100%;
}
.header_wrap .open .site_map_title_wrap *,
.header_wrap .open .site_map_list * {
  transform: translateY(0);
}
.header_wrap .open .site_map_content section article ul li .page_link a {
  transform: translateY(0);
}
.header_wrap .site_map_content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 5em 0;
  opacity: 0;
  transition: opacity 0.5s ease 0s;
  transition-delay: 0s;
}
.header_wrap .site_map_content section {
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
  overflow: hidden;
}
.header_wrap .site_map_content section div {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_wrap .site_map_content section div * {
  transform: translateY(200%);
  transition: transform 0.6s ease 0.3s, color 0.3s;
  transition-delay: 0.3s, 0s;
}
.header_wrap .site_map_content section div h1 {
  position: relative;
  font-size: 3em;
  margin: 0;
  font-weight: 700;
  color: #fff;
  text-align: left;
  animation: slide;
}
.header_wrap .site_map_content section div button {
  background-color: #1d9be2;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 1em;
  line-height: 40px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
}
.header_wrap .site_map_content section div button::before {
  content: "✖";
}
.header_wrap .site_map_content section div button:hover {
  transform: rotate(180deg);
}
.header_wrap .site_map_content section article {
  margin-top: 8rem;
  display: block;
  height: 500px;
}
.header_wrap .site_map_content section article ul {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
}
.header_wrap .site_map_content section article ul li a {
  width: 100%;
  margin: 0 0 2rem 0;
  transform: translateY(200%);
  transition: transform 0.6s ease 0.3s, color 0.3s;
  transition-delay: 0.3s, 0s;
}
.header_wrap .site_map_content section article ul li .major_title {
  color: #ffffff;
  font-size: 26px;
  text-decoration: none;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.header_wrap .site_map_content section article ul li .major_title:hover {
  color: #1d9be2;
}
.header_wrap .site_map_content section article ul li ul {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.header_wrap .site_map_content section article ul li .page_link {
  cursor: pointer;
  font-size: 21px;
  width: 100%;
  overflow: hidden;
}
.header_wrap .site_map_content section article ul li .page_link:hover a {
  color: #1d9be2;
}
.header_wrap .site_map_content section article ul li .page_link a {
  color: #ffffff;
  margin: 0 0 20px 0;
  text-decoration: none;
  transform: translateY(200%);
  transition: transform 0.6s ease 0.3s, color 0.3s;
  transition-delay: 0.3s, 0s;
}
@media screen and (max-width: 1250px) {
  .header_wrap .header .header_contents_wrap {
    grid-template-columns: 1fr 3fr 1fr;
    padding: 0 45px;
  }
  .header_wrap .site_map_title_wrap {
    padding: 0 45px;
  }
}
@media screen and (max-width: 1000px) {
  .header_wrap .header .header_contents_wrap {
    padding: 0 25px;
    grid-template-columns: 1fr 1fr;
  }
  .header_wrap .header .header_contents_wrap .gnb {
    display: none;
  }
  .header_wrap .header .header_contents_wrap .menu_wrap {
    display: none;
  }
  .header_wrap .header .header_contents_wrap .responsive_gnb {
    display: inline-block;
  }
  .header_wrap .header .contact_btn {
    display: none;
  }
  .header_wrap .header.hover {
    height: 80%;
  }
  .header_wrap .site_map_container {
    display: none;
  }
}

.header {
  display: block;
}
.header select {
  display: none;
  border: 1px solid #e1e1e1;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  margin-left: 15px;
  padding: 4px 10px;
}
.header select option {
  background-color: rgb(0, 0, 0);
  padding: 4px;
}
