@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
/*history S*/
.history {
  position: relative;
}
.history .historyContent {
  padding: 80px 0;
}
.history .historyContent:nth-child(2) {
  background-color: #f1f3f7;
}
.history ._grid {
  display: grid;
  grid-template-columns: 120px auto 1fr;
  align-items: flex-start;
}
.history ._grid ._item .border {
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #1d9be2;
}
.history ._grid ._item:nth-child(2) {
  margin: 10px 20px;
}
.history ._grid ._item:nth-child(3) {
  font-size: 18px;
  font-family: "SUIT";
  font-weight: 500;
  line-height: 28px;
  margin-left: 40px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .history ._grid ._item:nth-child(3) {
    font-size: 16px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  .history ._grid ._item:nth-child(3) {
    font-size: 14px;
    margin-left: 25px;
  }
}
.history ._grid ._item .color {
  color: #1d9be2;
}
.history ._grid ._item ._item-title {
  font-size: 25px;
  font-family: "SUIT";
  font-weight: 700;
  color: #1d9be2;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .history ._grid ._item ._item-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .history ._grid ._item ._item-title {
    font-size: 18px;
  }
}
.history ._grid ._item ._item-circle {
  transform: translate(calc(-50% + 1px), -50%);
}
.history ._grid ._item ._item-circle ._circleWhite {
  background: #fff !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50px;
  position: relative;
  z-index: 4;
}
.history ._grid ._item ._item-circle::after {
  display: block;
  content: "";
  animation: sparkle 1s linear infinite;
  background-color: rgba(80, 178, 233, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition-duration: 0.5s;
}
.history ._grid ._item ._item-circle::before {
  display: block;
  content: "";
  background-color: rgb(80, 178, 233);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px !important;
  height: 16px !important;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition-duration: 0.5s;
  z-index: -1;
}

/*history E*/
/*client S*/
.client .swiperBox {
  position: relative;
  margin: 80px auto;
}
.client .swiperBtn {
  position: absolute;
  top: 50%;
  transform: translate(0, calc(-50% - 80px));
  background-color: #1d9be2;
  width: 70px;
  height: 70px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 35px;
  cursor: pointer;
}
.client .swiperBtn.swiper-button-disabled {
  background-color: #f1f3f7;
  cursor: inherit;
}
@media screen and (max-width: 1198px) {
  .client .swiperBtn {
    z-index: 9;
  }
}
@media screen and (max-width: 767px) {
  .client .swiperBtn {
    display: none;
  }
}
.client .prevBtn {
  left: -130px;
}
@media screen and (max-width: 1198px) {
  .client .prevBtn {
    left: 0;
  }
}
.client .nextBtn {
  right: -130px;
}
@media screen and (max-width: 1198px) {
  .client .nextBtn {
    right: 0;
  }
}
.client table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.client table th {
  border: 1px solid #e1e1e1;
  border-top: 2px solid #1d9be2 !important;
  background-color: #f1f3f7;
  font-size: 18px;
  font-weight: 400;
  font-family: "SUIT";
  padding: 10px 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .client table th {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .client table th {
    font-size: 14px;
  }
}
.client table td {
  border: 1px solid #e1e1e1;
  font-size: 16px !important;
  font-weight: 400;
  font-family: "SUIT";
  padding: 10px 10px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .client table td {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .client table td {
    font-size: 12px;
  }
}
.client table td:nth-child(3) {
  padding: 10px 20px;
}

.privacyArea {
  height: 339px;
  border: 1px solid #c0c0c0;
  border-top: 3px solid #1f2a80;
  padding-top: 10px;
}
.privacyArea .privacyContent {
  height: 278px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  overflow-y: scroll;
  padding: 0 32px;
}
.privacyArea .privacyAgree {
  height: 60px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: flex-end;
  background-color: #F1F3F7;
  border-top: 1px solid #C0C0C0;
}
.privacyArea .privacyAgree p {
  font-family: "SUIT";
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
}
.privacyArea .halfRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 800px) {
  .privacyArea .halfRow {
    grid-template-columns: 1fr;
  }
}
.privacyArea .formField {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.privacyArea .formFieldFull {
  display: grid;
  grid-template-columns: 1fr 7fr;
}
@media screen and (max-width: 800px) {
  .privacyArea .formFieldFull {
    grid-template-columns: 1fr 3fr;
  }
}
.privacyArea .th {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  font-family: "SUIT";
  align-items: center;
  border-style: solid;
  background: #F1F3F7;
  justify-content: center;
  border-color: #C0C0C0;
  border-width: 0px 1px 1px 1px;
}
.privacyArea .th .star {
  height: fit-content;
  font-weight: 300;
  font-size: 15px;
  color: #be2d21;
  margin-right: 5px;
}
.privacyArea .th:first-child {
  border-left: none;
}
.privacyArea .td {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #C0C0C0;
  font-size: 16px;
  color: #666;
  padding: 20px;
  font-weight: 300;
}
.privacyArea .td input {
  border: 1px solid #d5d5d5;
  height: 40px;
  background-color: #fff;
  padding: 0 10px;
  width: 100%;
}
.privacyArea .td textArea {
  resize: none;
  border: 1px solid #d5d5d5;
  height: 220px;
  padding: 15px;
  width: 100%;
}
.privacyArea .productList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  color: #666;
  font-weight: 300;
}
.privacyArea .productList ul {
  width: calc(100% - 30px);
  padding: 15px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #C0C0C0;
}
.privacyArea .productList ul li {
  font-size: 15px;
  line-height: 40px;
  color: #666666;
  letter-spacing: 0;
}
.privacyArea .productList ul li .productCheck {
  position: relative;
}
.privacyArea .productList ul li .productCheck input {
  width: 0;
  height: 0;
  margin: 0;
  margin-right: 10px;
  opacity: 0;
  position: absolute;
}
.privacyArea .productList ul li .productCheck input:checked + label::before {
  content: "";
  background-image: url("/public/images/check.png");
}
.privacyArea .productList ul li .productCheck label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.privacyArea .productList ul li .productCheck label::before {
  width: 25px;
  height: 25px;
  content: "";
  display: block;
  cursor: pointer;
  background-image: url("/public/images/check_no.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1100px) {
  .privacyArea .productList ul li {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .privacyArea .productList ul li {
    width: 100%;
  }
}

.captcha {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

.submitWrap {
  margin-top: 50px;
}
.submitWrap p {
  font-family: "SUIT";
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
}
.submitWrap .submitBtn {
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 55px;
  margin-top: 50px;
  background: #1F2A80;
  transition: all 300ms;
}
.submitWrap .submitBtn p {
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;
}
.submitWrap .submitBtn:hover .arrow > div .arrow-1 {
  left: 5px;
}
.submitWrap .submitBtn:hover .arrow > div .vector-8 {
  left: 14px;
}
.submitWrap .submitBtn .arrow {
  align-items: flex-start;
  display: flex;
  min-width: 53px;
}
.submitWrap .submitBtn .arrow .overlap-group {
  height: 24px;
  position: relative;
  width: 53px;
}
.submitWrap .submitBtn .arrow .overlap-group .ellipse-9 {
  background-color: #ffffff;
  border-radius: 12px;
  height: 24px;
  left: 29px;
  position: absolute;
  top: 0;
  width: 24px;
}
.submitWrap .submitBtn .arrow .overlap-group .arrow-1 {
  height: 7px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 44px;
  transition: all 300ms;
}
.submitWrap .submitBtn .arrow .overlap-group .vector-8 {
  height: 1px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 20px;
  transition: all 300ms;
}

/*client E*/
/*notice S*/
.notice .gridTable {
  margin-top: 80px;
}
.notice .grid-table {
  grid-template-columns: 0.5fr 3fr 1fr 1fr;
  display: grid;
  text-align: center;
  border-bottom: 1px solid #e1e1e1;
  font-family: "SUIT";
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  padding: 20px 0;
  transform: scale(1);
  transition-duration: 0.3s;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .notice .grid-table {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .notice .grid-table {
    font-size: 14px !important;
  }
}
.notice .grid-table.th {
  border-top: 2px solid #1F2A80;
  color: #333;
}
.notice .grid-table.td {
  color: #666;
  cursor: pointer;
}
.notice .grid-table.td ._table-item:nth-child(2) {
  text-align: left;
}
.notice .grid-table.td ._table-item a {
  text-decoration: none;
  color: #666;
}
.notice .grid-table.td:hover {
  transform: scale(1.04);
  border: 0px solid #e1e1e1;
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}
.notice .paging ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
}
.notice .paging ul p {
  cursor: pointer;
  transition: all 300ms;
}
.notice .paging ul p:hover {
  scale: 1.5;
}
.notice .paging ul li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  color: #c0c0c0;
  font-family: "SUIT";
  border: 1px solid #e1e1e1;
}
.notice .paging ul li.active {
  border: 1px solid #1f2a80;
  background-color: #1f2a80;
  color: #fff;
}

.noticeTitle {
  font-size: 40px;
  font-family: "SUIT";
  font-weight: 700;
  margin-top: 80px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .noticeTitle {
    font-size: 35px;
  }
}
@media screen and (max-width: 767px) {
  .noticeTitle {
    font-size: 30px;
  }
}

.writeNoticeWrap {
  gap: 20px;
  display: grid;
  font-size: 25px;
  font-weight: 700;
  font-family: "SUIT";
  grid-template-columns: 1fr 9fr;
}

.text_editor_wrap {
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
}
.text_editor_wrap .quill.text_editor {
  width: 100%;
  height: 100%;
}

.writeNoticeBtn {
  margin-top: 80px;
}
.writeNoticeBtn .submitBtn {
  width: 30%;
  height: 50px;
  border: none;
  font-size: 23px;
  font-weight: 700;
  color: #ffffff;
  font-family: "SUIT";
  border-radius: 10px;
  background-color: #1F2A80;
  transition: all 300ms ease-in;
}
.writeNoticeBtn .submitBtn:hover {
  scale: 1.05;
  opacity: 0.9;
}

.contentDate {
  font-size: 20px;
  font-family: "SUIT";
  font-weight: 300;
  margin-top: 25px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contentDate {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .contentDate {
    font-size: 16px;
  }
}

.back {
  background-color: #333;
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  font-size: 22px;
  font-family: "SUIT";
  font-weight: 700;
  margin-top: 50px;
  display: inline-block;
}

.noticeContent {
  font-size: 14px;
  font-family: "SUIT";
  line-height: 24px;
  margin-top: 30px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .noticeContent {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .noticeContent {
    font-size: 14px;
  }
}

/*notice E*/
@keyframes sparkle {
  0% {
    width: 15px;
    height: 15px;
    opacity: 1;
  }
  100% {
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}
.esonImage {
  display: block !important;
}
@media screen and (max-width: 767px) {
  .esonImage {
    width: 900px !important;
  }
}

.imgScroll {
  position: relative;
}
.imgScroll .wrap {
  position: relative;
}
.imgScroll .threeGrid {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
}
@media screen and (max-width: 767px) {
  .imgScroll .threeGrid {
    grid-template-columns: 1fr !important;
  }
}
.imgScroll .threeGrid img:nth-child(1) {
  grid-column: span 2;
}
@media screen and (max-width: 767px) {
  .imgScroll .threeGrid img:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
}
.imgScroll .threeGrid img:nth-child(2) {
  grid-column: 1;
}
@media screen and (max-width: 767px) {
  .imgScroll .threeGrid img:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
}
.imgScroll .threeGrid img:nth-child(3) {
  grid-column: 2;
}
@media screen and (max-width: 767px) {
  .imgScroll .threeGrid img:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
  }
}

.tabBorder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 10px;
  overflow: hidden;
}

.mapTab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  border: 1px solid #e1e1e1;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.mapTab ._tab {
  padding: 20px;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mapTab ._tab {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .mapTab ._tab {
    font-size: 16px;
  }
}
.mapTab ._tab.on {
  background-color: #1d9be2;
  color: #fff;
  z-index: 0;
}

.tempo {
  text-align: center;
  font-size: 24px;
  line-height: 44px;
}

.tempoTitle {
  font-size: 36px;
  font-width: 800;
  margin-top: 60px;
  margin-bottom: 40px;
}