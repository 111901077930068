@charset "UTF-8";
@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");
li,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.titleArea {
  text-align: center;
}

/*common title S*/
.subTitle {
  font-size: 50px;
  font-weight: 900;
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .subTitle {
    font-size: 45px;
  }
}
@media screen and (max-width: 767px) {
  .subTitle {
    font-size: 40px;
  }
}

/*common title E*/
/*common title bot content S*/
.subContent {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-top: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .subContent {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .subContent {
    font-size: 16px;
  }
}

/*common title bot content E*/
/*common bg area S*/
.background {
  width: 100%;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
}
.background .bg_dark {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
}
.background .titleArea {
  position: relative;
  z-index: 2;
}
.background.intro {
  background-image: url("/public/images/backgorund_intro.png");
}
.background.solution {
  background-image: url("/public/images/backgorund_intro.png");
}

/*common bg area E*/
.scroll {
  transform: translate(0, -50%);
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  -ms-overflow-style: none;
  /* 인터넷 익스플로러 */
  scrollbar-width: none;
  /* 파이어폭스 */
}
@media screen and (max-width: 767px) {
  .scroll {
    width: 100%;
    overflow-x: auto;
  }
}
.scroll::-webkit-scrollbar {
  display: none;
  /* 크롬, 사파리, 오페라, 엣지 */
}
@media screen and (max-width: 767px) {
  .scroll::-webkit-scrollbar {
    width: 100%;
    overflow-x: auto;
  }
}

/* mobile img scroll -> swiper change S */
@media screen and (max-width: 1198px) {
  .imgSwiper {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
  .imgScroll {
    width: 90%;
    margin: 0 auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    /* 인터넷 익스플로러 */
    scrollbar-width: none;
    overflow-x: auto;
    /* 파이어폭스 */
  }
  .imgScroll::-webkit-scrollbar {
    display: none;
    /* 크롬, 사파리, 오페라, 엣지 */
  }
  .imgScroll .imgWrap {
    width: 900px !important;
  }
}
/* mobile img scroll -> swiper change E */
/* tab menu mobile auto height S */
.react-auto-height {
  transition-duration: 0.3s !important;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  overflow: hidden;
  z-index: 9;
  width: 100%;
  left: 0;
}

/* tab menu mobile auto height E */
/* tab menu pc scss S */
.tab {
  position: relative;
  font-size: 0;
}
.tab :root {
  --menuClick: 0;
}
@media screen and (min-width: 1200px) {
  .tab .tabMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tab .tabMenu svg {
    margin-left: 10px;
  }
  .tab .tabMenu li {
    font-size: 0;
    width: 100%;
    text-align: center;
    border: 1px solid #d7d7d7;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 1px solid #d7d7d7;
    background-color: #fff;
    position: relative;
  }
  .tab .tabMenu li:first-child {
    border-radius: 10px 0 0 10px;
  }
  .tab .tabMenu li:last-child {
    border-radius: 0 10px 10px 0;
  }
  .tab .tabMenu li:last-child {
    border-right: 0;
  }
  .tab .tabMenu li a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    z-index: 3;
    transition-duration: 0.3s;
    padding: 10px 0;
  }
  .tab .tabMenu li a:first-child {
    border-radius: 10px 0 0 10px;
  }
  .tab .tabMenu li a:last-child {
    border-radius: 0 10px 10px 0;
  }
  .tab .tabMenu li a.on {
    color: #fff !important;
  }
  .tab .tabMenu li dt {
    border: 1px solid #e1e1e1;
    font-weight: 500;
    font-size: 18px;
    line-height: 55px;
    font-family: "Noto Sans KR", sans-serif;
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
  .tab .tabMenu li dt dl {
    margin: 0;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
  }
  .tab .tabMenu li dt dl.add {
    color: #1d9be2;
  }
  .tab .tabMenu li dt dl:hover {
    color: #1d9be2;
  }
  .tab .tabBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #1d9be2;
  }
  .tab .move {
    transform: translate(calc(var(--menuClick) * 100%), 0) !important;
    transition-duration: 0.5s;
  }
}

/* tab menu pc scss E */
/*모바일 탭 S*/
@media screen and (max-width: 1199px) {
  .title_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobileNewTab {
    transform: translate(0, -50%);
    position: relative;
    z-index: 8;
  }
  .mobileNewTab .nowLocation {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .mobileNewTab .nowLocation .title {
    width: 100%;
    background-color: #f4f4f4;
    font-size: 14px;
    padding: 1.1rem 1.5rem;
    font-weight: 600;
    position: relative;
  }
  .mobileNewTab .nowLocation .title .tabMenu {
    background-color: #f4f4f4;
    width: 100%;
    position: inherit;
  }
  .mobileNewTab .nowLocation .title .tabMenu li a {
    text-decoration: none;
    display: block;
    padding: 1.1rem 1.5rem;
    font-size: 14px;
    line-height: 1.5;
    word-break: keep-all;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobileNewTab .nowLocation .title .tabMenu .subMenu {
    margin: 0;
    text-decoration: none;
    display: block;
    padding: 1.1rem 1.5rem;
    font-size: 14px;
    line-height: 1.5;
    word-break: keep-all;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
/*모바일 탭 E*/
.contentArea {
  /*컨텐츠 안에 타이틀 s*/
  /*컨텐츠 안에 타이틀 E*/
  /*컨텐츠 안에 보더 s*/
  /*컨텐츠 안에 보더 e*/
}
.contentArea .contentTitle {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-top: 120px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contentArea .contentTitle {
    margin-top: 90px;
    font-size: 35px;
  }
}
@media screen and (max-width: 767px) {
  .contentArea .contentTitle {
    margin-top: 80px;
    font-size: 30px;
  }
}
.contentArea.bar {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 50px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  color: #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-top: 50px;
}

/*box S*/
.grayBox {
  background-color: #f1f3f7;
  padding: 100px 0;
}
.grayBox img {
  width: 100%;
}

._typing-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  font-family: "Noto Sans KR", sans-serif;
  margin: 50px auto;
}
@media screen and (min-width: 768px) and (max-width: 1202px) {
  ._typing-title {
    width: 700px;
  }
  ._typing-title br {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  ._typing-title {
    font-size: 14px;
    width: 80%;
    font-size: 20px;
  }
  ._typing-title br {
    display: none;
  }
}

._typing-text {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin: 50px auto !important;
}
._typing-text .bigText {
  margin-top: 40px;
  font-size: 28px;
  font-weight: 800;
}
@media screen and (max-width: 767px) {
  ._typing-text {
    font-size: 14px;
    width: 90%;
  }
  ._typing-text br {
    display: none;
  }
}
@media screen and (max-width: 767px) and (max-width: 500px) {
  ._typing-text .typing_quot {
    font-size: 15px;
  }
}

.ceo_intro_box {
  height: 842px;
  display: flex;
  justify-content: end;
  overflow: hidden;
  position: relative;
  border-radius: 25px;
}
.ceo_intro_box .ceo_img {
  top: 0;
  left: 0;
  position: absolute;
}
.ceo_intro_box .ceo_intro {
  width: 33%;
  z-index: 1;
  padding: 44px;
  display: flex;
  font-size: 32px;
  color: #ffffff;
  line-height: 40px;
  background: #1d9be2;
  flex-direction: column;
  border-radius: 0 25px 25px 25px;
}
.ceo_intro_box .ceo_intro img {
  width: 100px;
  margin-bottom: 50px;
}
.ceo_intro_box .ceo_history {
  display: flex;
  font-size: 18px;
  font-family: "SUIT";
  font-weight: 500;
  letter-spacing: 0px;
  font-style: normal;
  flex-direction: column;
  border-left: 1px solid #ffffff;
  margin-top: 35px;
}
.ceo_intro_box .ceo_history span {
  margin-left: 45px;
}
.ceo_intro_box .ceo_history ._circleWhite {
  transform: translateX(-55%);
  background: #fff !important;
  width: 7px !important;
  height: 7px !important;
  border-radius: 50px;
  position: relative;
  z-index: 4;
}
@media screen and (max-width: 982px) {
  .ceo_intro_box .ceo_history {
    font-size: 14px;
  }
  .ceo_intro_box .ceo_history span {
    margin-left: 20px;
  }
}
@media screen and (max-width: 700px) {
  .ceo_intro_box .ceo_intro {
    font-size: 25px;
  }
  .ceo_intro_box .ceo_intro img {
    width: 100px;
    margin-bottom: 25px;
  }
  .ceo_intro_box .ceo_history {
    font-size: 12px;
  }
  .ceo_intro_box .ceo_history span {
    margin-left: 10px;
  }
}
@media screen and (max-width: 550px) {
  .ceo_intro_box {
    height: 500px;
  }
  .ceo_intro_box .ceo_intro {
    width: 40%;
    padding: 30px;
    font-size: 16px;
  }
  .ceo_intro_box .ceo_intro span {
    line-height: 23px;
  }
  .ceo_intro_box .ceo_history {
    margin-top: 10px;
    font-size: 10px;
  }
}

.ceo_shade_box {
  top: 5%;
  left: 2%;
  width: 75%;
  z-index: -1;
  height: 100%;
  position: absolute;
  background: #f1f3f7;
}

.ceo_letter_wrap {
  gap: 8px;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.ceo_letter_wrap span {
  font-family: "SUIT";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #333333;
}
.ceo_letter_wrap p {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #1f2a80;
  margin: 0;
}
.ceo_letter_wrap .letter_value {
  font-size: 16px;
  color: #666666;
  line-height: 25px;
  margin-bottom: 60px;
}

.introBox {
  background-color: #fff;
  padding: 100px 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .introBox {
    padding: 80px 0;
  }
}
@media screen and (max-width: 767px) {
  .introBox {
    padding: 60px 0;
  }
}
.introBox .wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.introBox img {
  width: 100%;
}

/*box E*/
/*table S*/
._table {
  margin-top: 80px;
}
._table td:nth-child(4) {
  width: 40%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._table td,
  ._table th {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  ._table td,
  ._table th {
    font-size: 12px !important;
  }
}

/*table E*/
/*tableBorder S*/
.tableTitle {
  font-weight: 400;
  font-size: 18px;
  margin-top: 80px;
}

.colorBorderTable._table {
  border-top: 2px solid #1d9be2 !important;
  margin: 40px auto;
}
.colorBorderTable._table th,
.colorBorderTable._table td {
  border: 1px solid #c0c0c0;
  padding: 10px;
  color: #333;
  height: 60px;
  line-height: 28px;
}
.colorBorderTable._table th {
  font-size: 20px;
}
.colorBorderTable._table td {
  font-size: 18px !important;
}
.colorBorderTable._table td.color,
.colorBorderTable._table td.otherColor {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.tableBorder {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  overflow: hidden;
  margin: 70px 0;
}
.tableBorder table tr:last-child th,
.tableBorder table tr:last-child td {
  border-bottom: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  border: 1px solid #c0c0c0;
  background-color: #f1f3f7;
  color: #1d9be2;
  font-size: 20px;
  font-weight: 700;
  font-family: "Noto Sans KR", sans-serif;
  border-left: 0;
  width: 120px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  table th {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  table th {
    font-size: 16px;
  }
}
table tr:first-child th,
table tr:first-child td {
  border-top: 0;
}
table td {
  border: 1px solid #c0c0c0;
  padding: 20px;
  background-color: #fff;
  color: #666;
  font-size: 18px;
  font-weight: 400;
  font-family: "Noto Sans KR", sans-serif;
  border-right: 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  table td {
    font-size: 16px;
    padding: 15px;
  }
}
@media screen and (max-width: 767px) {
  table td {
    font-size: 14px;
    padding: 10px;
  }
}

/*tableBorder E*/
/*mobile img scroll -> swiper S*/
.swiper-pagination {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
@media screen and (min-width: 1199px) {
  .swiper-pagination {
    display: none;
  }
}
.swiper-pagination span {
  cursor: pointer;
  font-size: 12px;
  display: block;
  padding: 8px 10px;
  border: 1px solid #c0c0c0;
  color: #c0c0c0;
  border-radius: 3px;
}
.swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #1d9be2;
  color: #fff;
}

/*mobile img scroll -> swiper E*/
/*box grid S*/
._box-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 60px;
  gap: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._box-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  ._box-grid {
    grid-template-columns: 1fr 1fr;
  }
}
._box-grid ._box-item {
  border: 1px solid #e1e1e1;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  ._box-grid ._box-item img {
    width: 60%;
  }
}
._box-grid ._box-item ._box-text {
  display: grid;
  grid-template-rows: 74px 1fr;
}
._box-grid ._box-item ._box-text ._box-title {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1f2a80;
  padding: 10px 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._box-grid ._box-item ._box-text ._box-title {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  ._box-grid ._box-item ._box-text ._box-title {
    font-size: 16px;
  }
}
._box-grid ._box-item ._box-text ._box-content {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._box-grid ._box-item ._box-text ._box-content {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  ._box-grid ._box-item ._box-text ._box-content {
    font-size: 14px;
  }
}

/*box grid E*/
/* dark blue box S */
.darkGrid {
  display: grid;
  gap: 20px;
  margin: 40px 0;
}
.darkGrid .btnArea {
  padding: 30px 0 !important;
}
.darkGrid .btnArea button {
  margin-bottom: 30px;
  border: 1px solid #1f2a80;
  color: #1f2a80;
  background-color: #fff;
  border-radius: 50px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.darkGrid ul {
  width: 70%;
  margin: 0 auto;
}
.darkGrid ul li {
  list-style: disc;
  text-align: left;
}
.darkGrid.four {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.four {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.four {
    grid-template-columns: 1fr;
  }
}
.darkGrid.two {
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.two {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.two {
    grid-template-columns: 1fr;
  }
}
.darkGrid.two .dark-content {
  font-size: 18px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.two .dark-content {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.two .dark-content {
    font-size: 16px;
  }
}
.darkGrid.two .dark-content ._dark-content-title {
  font-size: 22px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.two .dark-content ._dark-content-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.two .dark-content ._dark-content-title {
    font-size: 18px;
  }
}
.darkGrid.three {
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.three {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.three {
    grid-template-columns: 1fr;
  }
}
.darkGrid.three .dark-content {
  font-size: 18px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.three .dark-content {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.three .dark-content {
    font-size: 14px;
  }
}
.darkGrid.three .dark-content ._dark-content-title {
  font-size: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkGrid.three .dark-content ._dark-content-title {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .darkGrid.three .dark-content ._dark-content-title {
    font-size: 16px;
  }
}

.darkBlue {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  overflow: hidden;
}
.darkBlue .dark-top {
  padding: 10px 0;
  font-size: 24px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  text-align: center;
}
.darkBlue .dark-top._Blue {
  background-color: #1d9be2;
  color: #fff;
}
.darkBlue .dark-top._Blue + .dark-content ._dark-content-title {
  color: #1f2a80;
  font-size: 22px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkBlue .dark-top._Blue + .dark-content ._dark-content-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .darkBlue .dark-top._Blue + .dark-content ._dark-content-title {
    font-size: 18px;
  }
}
.darkBlue .dark-top._darkBlue {
  background-color: #1f2a80;
  color: #fff;
}
.darkBlue .dark-top._darkBlue + .dark-content ._dark-content-title {
  color: #1f2a80;
  font-size: 22px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkBlue .dark-top._darkBlue + .dark-content ._dark-content-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .darkBlue .dark-top._darkBlue + .dark-content ._dark-content-title {
    font-size: 18px;
  }
}
.darkBlue .dark-top._darkGray {
  background-color: #f0f0f0;
  color: #1f2a80;
}
.darkBlue .dark-top._darkGray + .dark-content ._dark-content-title {
  color: #1d9be2;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .darkBlue .dark-top {
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .darkBlue .dark-top {
    font-size: 20px;
  }
}
.darkBlue .dark-content {
  color: #333;
  padding: 10px 0;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding: 40px 0;
  text-align: center;
}
.darkBlue .dark-content ._dark-content-title {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  line-height: 30px;
  margin-top: 10px;
}
.darkBlue .dark-content ._dark-content-title:first-child {
  margin-top: 0;
}

/* dark blue box E */
.imgSwiper {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .imgSwiper img {
    width: 100%;
  }
}

.max-width ._typing-text {
  margin: 0 auto;
  white-space: pre-line;
}
@media screen and (min-width: 768px) and (max-width: 1202px) {
  .max-width ._typing-text {
    width: 700px;
  }
  .max-width ._typing-text br {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .max-width ._typing-text {
    width: 80%;
  }
  .max-width ._typing-text br {
    display: none;
  }
}

.smallBig {
  font-size: 26px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .smallBig {
    font-size: 18px;
  }
}

/*swiper S*/
.swiperRelative {
  position: relative;
}

.swiper-pagination {
  position: absolute;
  top: calc(95% + 20px) !important;
  overflow: hidden;
}

.swiper-pagination-progressbar-fill {
  background-color: #1d9be2 !important;
}

/*swiper E*/
.center button {
  margin: 0 auto !important;
}

.color {
  color: #1d9be2 !important;
}

.otherColor {
  color: #1f2a80 !important;
}

.fixedMenu {
  position: fixed;
  bottom: 10%;
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  .fixedMenu {
    display: none;
  }
}
.fixedMenu svg {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
.fixedMenu.fixOpen svg {
  transform: rotate(0deg);
  transition-duration: 0.3s;
}
.fixedMenu ._fixed-rotate {
  cursor: pointer;
  background-color: #1d9be2;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  position: absolute;
  left: 0%;
  transform: translate(calc(-100% + 51px), 0) rotate(90deg);
  border-radius: 0px 0px 15px 15px;
}
.fixedMenu ._fixed-rotate ._fixed-text {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: #fff;
}
.fixedMenu ._fixed-rotate ._fixed-text svg {
  font-size: 24px;
}
.fixedMenu ._fixed-menu {
  background-color: #fff;
  display: flex;
  gap: 20px;
  padding: 20px;
  height: 124px;
}
.fixedMenu ._fixed-menu a {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 20px;
}
.fixedMenu ._fixed-menu a img {
  width: 75px;
  margin: 0 auto;
}
.fixedMenu ._fixed-menu a .text {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #444444;
}

.mapAddress {
  padding: 7px 11px;
  border: 1px solid #dfdfdf;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 2px 2px;
  background-color: #f9f9f9;
}

.en table th {
  width: 220px;
}
.en ._typing-title {
  font-size: 23px;
}
.en .ceo_intro_box {
  height: inherit !important;
}
.en ._typing-text {
  max-width: 1000px;
  font-size: 18px !important;
}
@media screen and (max-width: 767px) {
  .en ._typing-text {
    font-size: 16px !important;
  }
}
.en ._typing-content {
  max-width: 1000px;
  font-size: 16px !important;
}
@media screen and (max-width: 767px) {
  .en ._typing-content {
    font-size: 14px !important;
  }
}
