@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
.solution {
  /*mes S*/
  /*mes E*/
}
.solution .grid {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .solution .grid {
    grid-template-columns: auto 1fr auto 1fr;
  }
}
@media screen and (max-width: 767px) {
  .solution .grid {
    grid-template-columns: auto 1fr;
  }
}
.solution .grid .item {
  border: 1px solid #e1e1e1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
  border-left: 0;
}
@media screen and (min-width: 1024px) {
  :is(.solution .grid .item:nth-child(1), .solution .grid .item:nth-child(7)) {
    border-left: 1px solid #e1e1e1;
  }
  .solution .grid .item:nth-child(n+7) {
    border-bottom: 1px solid #e1e1e1;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  :is(.solution .grid .item:nth-child(1), .solution .grid .item:nth-child(5), .solution .grid .item:nth-child(9)) {
    border-left: 1px solid #e1e1e1;
  }
  .solution .grid .item:nth-child(n+9) {
    border-bottom: 1px solid #e1e1e1;
  }
}
@media screen and (max-width: 767px) {
  .solution .grid .item {
    grid-template-columns: 1fr 1fr;
  }
  :is(.solution .grid .item:nth-child(1), .solution .grid .item:nth-child(3), .solution .grid .item:nth-child(5), .solution .grid .item:nth-child(7), .solution .grid .item:nth-child(8), .solution .grid .item:nth-child(9), .solution .grid .item:nth-child(11)) {
    border-left: 1px solid #e1e1e1;
  }
  .solution .grid .item:nth-child(n+11) {
    border-bottom: 1px solid #e1e1e1;
  }
}
.solution .grid .item.even {
  background-color: #f0f0f0;
  padding: 10px;
}
.solution .grid .item.odd {
  font-family: "SUIT";
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #1F2A80;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .solution .grid .item.odd {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .solution .grid .item.odd {
    font-size: 14px;
  }
}
.solution .grid .item.odd .content {
  font-family: "SUIT";
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #666666;
}
.solution .solutionSwiper .swiper {
  padding: 0 1px;
}
.solution .solutionSwiper .swiper-slide {
  height: 100%;
}
.solution .solutionSwiper .swiper-slide:last-child {
  margin-right: 0 !important;
}
.solution ._gridBox {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #e1e1e1;
  height: 100%;
  background-color: #fff;
  margin-top: 40px;
}
.solution ._gridBox ._gridBox-top {
  background-color: #1d9be2;
  color: #fff;
  padding: 10px 0;
  font-size: 20px;
  font-family: "SUIT";
  font-weight: 700;
}
.solution ._gridBox ._gridBox-content {
  color: #333;
  padding: 10px 0;
  font-size: 18px;
  font-family: "SUIT";
  font-weight: 400;
  line-height: 28px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solution .centerTitle {
  font-family: "SUIT";
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #1F2A80;
}
@media screen and (min-width: 1024px) {
  .solution .factory .swiper {
    overflow: inherit !important;
  }
}
.solution .factory ._gridBox-top {
  background-color: #1F2A80;
}
.solution .factory ._gridBox-content {
  flex-direction: column;
  height: inherit !important;
  padding: 0;
}
.solution .factory ._gridBox-content p {
  font-family: "SUIT";
  padding: 10px 0;
  margin: 0;
  line-height: 30px;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
}
.solution .factory ._gridBox-content p:last-child {
  border-bottom: 0;
}
.solution .mesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .solution .mesGrid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .solution .mesGrid {
    grid-template-columns: 1fr;
  }
}
.solution .mesGrid ._mesGrid {
  display: grid;
  grid-template-columns: 180px 1fr;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .solution .mesGrid ._mesGrid {
    grid-template-columns: 1fr;
  }
  .solution .mesGrid ._mesGrid img {
    width: 70px;
  }
}
.solution .mesGrid ._mesGrid ._mesItem {
  padding: 10px;
  background-color: #f0f0f0;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e1e1e1;
}
@media screen and (max-width: 767px) {
  .solution .mesGrid ._mesGrid ._mesItem {
    height: 80px;
  }
}
.solution .mesGrid ._mesGrid ._mesTxt {
  padding: 20px;
  font-family: "Noto Sans KR";
}
.solution .mesGrid ._mesGrid ._mesTxt ._mesTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1F2A80;
}
@media screen and (max-width: 767px) {
  .solution .mesGrid ._mesGrid ._mesTxt ._mesTitle {
    font-size: 18px;
  }
}
.solution .mesGrid ._mesGrid ._mesTxt ._mesContent {
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
}
@media screen and (max-width: 767px) {
  .solution .mesGrid ._mesGrid ._mesTxt ._mesContent {
    font-size: 16px;
  }
}
.solution .mesImg {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

._contry {
  border: 1px solid #1F2A80;
  font-size: 0;
}
._contry img {
  width: 50%;
  height: 96px;
  padding: 40px;
}
@media screen and (max-width: 1024px) {
  ._contry img {
    width: calc(100% - 20px);
    height: inherit !important;
    padding: 10px;
  }
}
._contry ._contry-text {
  border-top: 1px solid #1F2A80;
  padding: 15px;
  font-family: "SUIT";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._contry ._contry-text {
    font-size: 18px;
    padding: 15px;
  }
}
@media screen and (max-width: 767px) {
  ._contry ._contry-text {
    font-size: 14px;
    padding: 10px;
  }
}

.small-contry {
  width: 900px;
  margin: 40px auto 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .small-contry {
    width: 100%;
  }
}
.small-contry ._contry ._contry-text {
  color: #fff;
  background-color: #1F2A80;
}

.many-contry {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .many-contry {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .many-contry {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.many-contry ._contry ._contry-text {
  color: #1F2A80;
  background-color: #1d9be2;
}

/* big shark S */
.sharkGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sharkGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .sharkGrid {
    grid-template-columns: 1fr;
  }
}
.sharkGrid.dron ._sharkItem {
  border: 0;
}
.sharkGrid ._sharkItem {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #c0c0c0;
}
.sharkGrid ._sharkItem ._sharkTitle {
  background-color: #f0f0f0;
  color: #1F2A80;
  font-family: "SUIT";
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  padding: 18px;
  text-align: center;
}
.sharkGrid ._sharkItem ._sharkContent {
  text-align: center;
  padding: 34px 75px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sharkGrid ._sharkItem ._sharkContent {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .sharkGrid ._sharkItem ._sharkContent {
    padding: 20px;
  }
}
.sharkGrid ._sharkItem ._sharkContent .text {
  font-family: "SUIT";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.sharkGrid ._sharkItem ._sharkContent img {
  width: 100%;
  margin-bottom: 16px;
}

/* big shark E */
/* Dron S */
.droncenter {
  width: inherit !important;
}
@media screen and (max-width: 767px) {
  .droncenter {
    width: 50% !important;
  }
}

/* Dron E */
.grid.five {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
}
.grid.five ._five-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px 10px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid.five {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .grid.five {
    grid-template-columns: 1fr 1fr;
  }
}
.grid.five ._five-title {
  font-size: 22px;
  font-weight: 700;
  color: #1F2A80;
}
.grid.five ._five-content {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.grid.five img {
  width: 50%;
  margin: 0 auto;
}
.grid.two {
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.grid.two ._two-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.grid.two ._two-item ._bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.grid.two ._two-item:nth-child(odd) {
  text-align: left;
}
.grid.two ._two-item:nth-child(even) {
  text-align: right;
}
.grid.two ._two-item.pic_01 {
  background-image: url("/public/images/vision_pic_01.png");
}
.grid.two ._two-item.pic_02 {
  background-image: url("/public/images/vision_pic_02.png");
}
.grid.two ._two-item.pic_03 {
  background-image: url("/public/images/vision_pic_03.png");
}
.grid.two ._two-item.pic_04 {
  background-image: url("/public/images/vision_pic_04.png");
}
.grid.two ._two-item.pic_05 {
  background-image: url("/public/images/vision_pic_05.png");
}
.grid.two ._two-item.pic_06 {
  background-image: url("/public/images/vision_pic_06.png");
}
.grid.two ._two-item.pic_07 {
  background-image: url("/public/images/vision_pic_07.png");
}
.grid.two ._two-item.pic_08 {
  background-image: url("/public/images/vision_pic_08.png");
}
.grid.two ._two-item.pic_09 {
  background-image: url("/public/images/vision_pic_09.png");
}
.grid.two ._two-item.pic_10 {
  background-image: url("/public/images/vision_pic_10.png");
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid.two {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .grid.two {
    grid-template-columns: 1fr;
  }
}
.grid.two ._two-title {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .grid.two ._two-title {
    font-size: 18px;
  }
}
.grid.two ._two-content {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .grid.two ._two-content {
    font-size: 14px;
  }
}

.grid.vision {
  gap: 0;
}