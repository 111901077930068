@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
.blueBox {
  padding: 40px;
  background: #B1CFF0;
  position: relative;
}
.blueBox ._blue-title {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #fff;
}
.blueBox ._blue-content {
  font-family: "SUIT";
  font-weight: 300;
  margin: 20px 0 30px;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.blueBox ._blue-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-20%, -50%);
}
@media screen and (max-width: 1024px) {
  .blueBox ._blue-right {
    display: none;
  }
}