.lostPage {
  margin: 90px auto 0;
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
}

._lost-title {
  display: inline-block;
  position: relative;
  font-size: 0;
  width: 50%;
  font-size: 0;
}
._lost-title > svg {
  width: 100%;
  position: relative;
  z-index: 2;
}
._lost-title > svg path {
  fill: #fff;
}
._lost-title > svg polygon {
  fill: #fff;
}
._lost-title ._404-bg {
  position: absolute;
  bottom: 0;
  left: 1px;
  z-index: 1;
  width: calc(100% - 2px);
  overflow: hidden;
  height: 100%;
}
._lost-title ._404-bg .wave {
  width: 100%;
  height: 100%;
}
._lost-title ._404-bg svg {
  width: 100%;
  height: 100%;
}
._lost-title ._404-bg svg path {
  fill: rgb(29, 155, 226);
  position: absolute;
  bottom: 0;
  left: 0;
}

._lost-content {
  font-size: 40px;
  font-family: "SUIT";
  font-weight: 100;
  margin-top: 80px;
}