@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
._blue-btn :root {
  --background: #1F2A80;
  --borderRad: 10px;
  --margin: 0;
}
._blue-btn button {
  margin: var(--margin);
  background: var(--background);
  border-radius: var(--borderRad);
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  margin-top: 20px;
}
._blue-btn button ._btn-text {
  font-family: "SUIT";
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #fff;
}
._blue-btn button ._btn-anmation {
  position: relative;
  margin-left: 50px;
}
._blue-btn button ._btn-anmation ._btn-bar {
  background-color: #fff;
  height: 2px;
  width: 50px;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  transition-duration: 0.5s;
}
._blue-btn button ._btn-anmation ._btn-circle {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
}
._blue-btn button ._btn-anmation ._btn-circle ._circle-arrow {
  background-color: #1F2A80;
  height: 2px;
  width: 50px;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-20%, -50%);
  transition-duration: 0.5s;
}
._blue-btn button ._btn-anmation ._btn-circle ._circle-arrow::after {
  content: "";
  background-color: #1F2A80;
  height: 2px;
  width: 8px;
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(0%, calc(0% - 1px)) rotate(0);
  border-radius: 8px;
  transition-duration: 1s;
}
._blue-btn button ._btn-anmation ._btn-circle ._circle-arrow::before {
  content: "";
  background-color: #1F2A80;
  height: 2px;
  width: 8px;
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(0%, calc(0% + 1px)) rotate(0);
  border-radius: 8px;
  transition-duration: 1s;
}
._blue-btn button ._btn-anmation.add ._circle-arrow::after {
  content: "";
  transform: translate(0%, calc(-100% - 1px)) rotate(40deg);
}
._blue-btn button ._btn-anmation.add ._circle-arrow::before {
  content: "";
  transform: translate(0%, calc(100% + 1px)) rotate(-40deg);
}
._blue-btn button:hover ._btn-bar {
  background-color: #fff;
  height: 2px;
  width: 45px;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
}
._blue-btn button:hover ._circle-arrow {
  transform: translate(-10%, -50%) !important;
}