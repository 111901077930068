@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");
@import url("https: //cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/static/woff2/SUIT.css");
a {
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .mNone {
    display: none;
  }
}

.fp-tooltip {
  overflow: inherit !important;
  font-family: "Noto Sans KR", sans-serif !important;
  top: -7px !important;
}

._main-title {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  ._main-title {
    font-size: 25px;
    line-height: 35px;
  }
}
._main-title .color {
  color: #4fbfff !important;
}

.colorGaion {
  color: #4fbfff !important;
}

._main-content {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 300;
  font-size: 32px;
  line-height: 50px;
  color: #FFFFFF;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  ._main-content {
    font-size: 20px;
    line-height: 35px;
  }
  ._main-content br {
    display: none;
  }
}
._main-content .color {
  color: #1d9be2;
}

._main-title,
._main-content {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .section04 {
    min-height: auto !important;
    height: auto !important;
  }
  .section04 ._main-title br {
    display: none;
  }
}

.active ._main-title {
  animation: scaleAnimation 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
.active ._main-content {
  animation: scaleAnimation 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
.active.section02 ._grid-item:nth-child(1) {
  animation: scaleAnimation 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  transform-origin: center !important;
}
.active.section02 ._grid-item:nth-child(2) {
  animation: scaleAnimation 1.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  transform-origin: center !important;
}
.active.section02 ._grid-item:nth-child(3) {
  animation: scaleAnimation 1.4s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  transform-origin: center !important;
}
.active.section02 ._grid-item:nth-child(4) {
  animation: scaleAnimation 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  transform-origin: center !important;
}
.active.section02 .grid.two {
  text-align: inherit !important;
}

.grid {
  gap: 40px;
  margin-top: 40px;
  font-family: "Noto Sans KR", sans-serif;
}
.grid.two {
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 767px) {
  .grid.two {
    grid-template-columns: 1fr;
  }
}
.grid.four {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 767px) {
  .grid.four {
    grid-template-columns: 1fr 1fr;
  }
}
.grid ._content-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 55px;
  color: #fff;
}
.grid ._content-title.color {
  color: #7AB9DD;
}
@media screen and (max-width: 767px) {
  .grid ._content-title {
    font-size: 18px;
    line-height: 25px;
  }
}
.grid ._content-hr {
  border: 0;
  border-top: 2px dashed #fff;
  width: 50%;
  margin: 20px 0;
}
.grid ._content-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .grid ._content-text {
    font-size: 14px;
    line-height: 25px;
  }
}

.fullpage-wrapper {
  /*grid S*/
  /*grid E*/
}
.fullpage-wrapper [class^=section] {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.fullpage-wrapper .changeBg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fullpage-wrapper .section .wrap {
  position: relative;
  z-index: 2;
}
.fullpage-wrapper .section .bg {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.fullpage-wrapper .section02 {
  background-image: url("/public/images/main/main_02.png");
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section02 .grid {
    grid-template-columns: 1fr;
  }
  .fullpage-wrapper .section02 .grid ._content-text br {
    display: none;
  }
}
.fullpage-wrapper .section02 .grid ._grid-item {
  opacity: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.fullpage-wrapper .section02 .grid ._grid-item ._item-icon img {
  width: 70px;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section02 .grid ._grid-item ._item-icon img {
    width: 40px;
  }
}
.fullpage-wrapper .section02 .grid ._grid-item:nth-child(1) {
  background-image: url("/public/images/main/section_02_bg_01.png");
}
.fullpage-wrapper .section02 .grid ._grid-item:nth-child(2) {
  background-image: url("/public/images/main/section_02_bg_02.png");
}
.fullpage-wrapper .section02 .grid ._grid-item:nth-child(3) {
  background-image: url("/public/images/main/section_02_bg_03.png");
}
.fullpage-wrapper .section02 .grid ._grid-item:nth-child(4) {
  background-image: url("/public/images/main/section_02_bg_04.png");
}
.fullpage-wrapper .section03 {
  background-image: url("/public/images/main/main_04.png");
}
.fullpage-wrapper .section03 ._main-title {
  transform-origin: center !important;
}
.fullpage-wrapper .section03 ._main-content {
  transform-origin: center !important;
}
.fullpage-wrapper .section03 .grid ._grid-four-item {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  position: relative;
  top: 0;
  transition-duration: 0.3s;
}
.fullpage-wrapper .section03 .grid ._grid-four-item:hover {
  top: -10px;
  border: 1px solid #1d9be2;
}
.fullpage-wrapper .section03 .grid .swiperBot {
  justify-content: flex-end;
}
.fullpage-wrapper .section03 .grid .swiperBot ._swiperBot-text {
  font-size: 16px;
}
.fullpage-wrapper .section03 .grid .swiperBot .mainArrow ._mainArw-bar {
  width: 50px !important;
}
.fullpage-wrapper .section03 .grid .swiperBot .mainArrow ._mainArw-cicle {
  width: 35px;
  height: 35px;
}
.fullpage-wrapper .section04 {
  background-image: url("/public/images/main/main_03.png");
}
.fullpage-wrapper .section04 ._main-title {
  transform-origin: center !important;
}
.fullpage-wrapper .section04 ._main-content {
  transform-origin: center !important;
}
.fullpage-wrapper .section04 .grid.special {
  grid-template-columns: auto 1fr;
}
@media screen and (max-width: 1024px) {
  .fullpage-wrapper .section04 .grid.special {
    grid-template-columns: 1fr;
  }
}
.fullpage-wrapper .section04 .grid.special ._special-item {
  display: flex;
  flex-direction: column;
}
.fullpage-wrapper .section04 .grid.special ._special-item:nth-child(1) {
  background-color: rgba(31, 42, 128, 0.5);
  padding: 20px 20px 50px;
}
@media screen and (max-width: 1024px) {
  .fullpage-wrapper .section04 .grid.special ._special-item:nth-child(1) {
    display: NONE;
  }
}
.fullpage-wrapper .section04 .grid.special ._special-item:nth-child(2) {
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
}
.fullpage-wrapper .section04 .grid.special ._item-hr {
  height: 25px;
  position: relative;
  top: -10px;
}
.fullpage-wrapper .section04 .grid.special ._item-hr .hr {
  margin: 0;
  width: 100%;
  height: 50px;
  border: 0;
  border-right: 2px dashed #fff;
}
.fullpage-wrapper .section04 .grid.special ._item-hr:last-child .hr {
  position: relative;
}
.fullpage-wrapper .section04 .grid.special ._item-hr:last-child .hr::after {
  content: "";
  width: 0px;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  transform: rotate(-180deg);
  bottom: -5px;
  right: -8px;
}
.fullpage-wrapper .section04 .grid.special ._special-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.fullpage-wrapper .section04 .grid.special ._special-area img {
  width: 50px;
}
.fullpage-wrapper .section04 .grid.special ._special-area ._item-text {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #FFFFFF;
}
.fullpage-wrapper .section05 {
  background-image: url("/public/images/main/main_05.png");
}
.fullpage-wrapper .section05 .grid ._grid-four-item {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  position: relative;
  top: 0;
  transition-duration: 0.3s;
}
.fullpage-wrapper .section05 .grid ._grid-four-item:hover {
  top: -10px;
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section05 .grid ._grid-four-item:hover ._grid-four-type {
  border: 1px solid #fff;
}
.fullpage-wrapper .section05 .grid ._grid-four-item:hover .mainArrow ._mainArw-cicle {
  background-color: #fff;
}
.fullpage-wrapper .section05 .grid ._grid-four-item:hover .mainArrow ._mainArw-cicle ._mainArw-arrow {
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section05 .grid ._grid-four-item:hover .mainArrow ._mainArw-cicle ._mainArw-arrow::after {
  content: "";
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section05 .grid ._grid-four-item:hover .mainArrow ._mainArw-cicle ._mainArw-arrow::before {
  content: "";
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section05 .grid ._grid-four-item ._grid-four-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.fullpage-wrapper .section05 .grid ._grid-four-item ._grid-four-top ._grid-four-type {
  padding: 5px 15px;
  background-color: #1d9be2;
  color: #fff;
  font-size: 18px;
  font-family: "Noto Sans KR", sans-serif;
}
.fullpage-wrapper .section05 .grid ._grid-four-item ._grid-four-top ._grid-four-date {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
}
.fullpage-wrapper .section05 .grid ._grid-four-item ._content-title {
  font-size: 20px;
  line-height: 30px;
}
.fullpage-wrapper .section05 .grid ._grid-four-item ._content-text {
  font-size: 18px;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fullpage-wrapper .section05 .grid ._grid-four-item ._content-text p {
  margin: 0;
}
.fullpage-wrapper .section05 .grid .swiperBot {
  justify-content: flex-end;
}
.fullpage-wrapper .section05 .grid .swiperBot ._swiperBot-text {
  font-size: 16px;
}
.fullpage-wrapper .section05 .grid .swiperBot .mainArrow ._mainArw-bar {
  width: 50px !important;
}
.fullpage-wrapper .section05 .grid .swiperBot .mainArrow ._mainArw-cicle {
  width: 35px;
  height: 35px;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .grid {
    grid-template-columns: 1fr !important;
  }
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section {
    padding: 80px 0;
  }
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section.footer {
    padding: 0px 0;
  }
}
.fullpage-wrapper .fp-responsive .fp-section,
.fullpage-wrapper .fp-responsive .fp-slide,
.fullpage-wrapper .fp-responsive .fp-tableCell,
.fullpage-wrapper .fp-auto-height {
  height: auto !important;
}
.fullpage-wrapper .fp-watermark {
  opacity: 0;
}
.fullpage-wrapper .grid {
  display: grid;
}
.fullpage-wrapper .section {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.fullpage-wrapper .section .wrap {
  height: 100%;
}
.fullpage-wrapper .section01 {
  /* mainArea S */
  /* mainArea E */
  /* main progress S*/
  /* main progress E*/
  /* _swiper-title S*/
  /* _swiper-title E*/
  /* swiper active animation S*/
  /* swiper active animation E*/
}
.fullpage-wrapper .section01 ._swiperBot-text {
  border: 1px solid #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 18px;
}
.fullpage-wrapper .section01 .swiperBot {
  gap: 0;
}
.fullpage-wrapper .section01 .swiperBot img {
  margin-left: 20px;
}
.fullpage-wrapper .section01 .swiperBot:hover .mainArrow ._mainArw-cicle {
  background-color: #fff;
}
.fullpage-wrapper .section01 .swiperBot:hover .mainArrow ._mainArw-cicle ._mainArw-arrow {
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section01 .swiperBot:hover .mainArrow ._mainArw-cicle ._mainArw-arrow::after {
  content: "";
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section01 .swiperBot:hover .mainArrow ._mainArw-cicle ._mainArw-arrow::before {
  content: "";
  background-color: rgba(29, 155, 226, 0.7);
}
.fullpage-wrapper .section01 video {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}
.fullpage-wrapper .section01 .videoBg {
  background-color: rgba(0, 0, 0, 0.5333333333);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.fullpage-wrapper .section01 .mainArea {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* _maintop S */
  /* _maintop E */
  /* _mainBottom E */
  /* _mainBottom E */
}
@media screen and (max-width: 1024px) {
  .fullpage-wrapper .section01 .mainArea {
    width: 90%;
    margin: 0 auto;
  }
}
.fullpage-wrapper .section01 .mainArea ._maintop {
  display: flex;
  align-items: center;
  /* _mainIcon S */
  /* _mainIcon E */
  /* _mainLogo S */
  /* _mainLogo E */
}
.fullpage-wrapper .section01 .mainArea ._maintop hr {
  width: 50px;
  border: 0;
  border-top: 4px dashed #fff;
}
@media screen and (max-width: 1024px) {
  .fullpage-wrapper .section01 .mainArea ._maintop hr {
    display: none;
  }
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainIcon {
  position: relative;
  width: 210px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .fullpage-wrapper .section01 .mainArea ._maintop ._mainIcon {
    display: none;
  }
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainIcon:nth-child(1) img {
  width: 185px;
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainIcon:last-child img {
  width: 175px;
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainIcon ._mainIcon-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -4px;
  left: -4px;
  border: 4px dashed #fff;
  border-radius: 500px;
  animation: rotate 5s linear infinite;
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainLogo {
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  border-radius: 500px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .mainArea ._maintop ._mainLogo {
    margin-top: 60px;
    width: 250px;
    height: 250px;
  }
  .fullpage-wrapper .section01 .mainArea ._maintop ._mainLogo img {
    width: 60%;
  }
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainLogo [class^=_mainLogoAnimation] {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
  border-radius: 500px;
}
.fullpage-wrapper .section01 .mainArea ._maintop ._mainLogo ._mainLogoAnimation1 {
  animation: scalCircle1 4s linear infinite;
}
.fullpage-wrapper .section01 .mainArea ._mainBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fullpage-wrapper .section01 .mainArea ._mainBottom ._mainBottom-title {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .mainArea ._mainBottom ._mainBottom-title {
    font-size: 22px;
    margin-top: 20px;
  }
}
.fullpage-wrapper .section01 .mainArea ._mainBottom ._mainBottom-title span {
  color: #1d9be2;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .mainArea ._mainBottom ._mainBottom-title span {
    font-size: 40px;
    line-height: 0px;
  }
}
.fullpage-wrapper .section01 .mainArea ._mainBottom ._mainBottom-content {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #FFFFFF;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .mainArea ._mainBottom ._mainBottom-content {
    font-size: 18px;
    line-height: 0px;
  }
}
.fullpage-wrapper .section01 .swiper-progress-container {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 3;
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress {
  height: 2px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.4666666667);
  position: relative;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress {
    display: none;
  }
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress.startAnimation {
    display: block;
  }
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress .progress {
  position: absolute;
  background-color: #fff;
  height: 100%;
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress .progress.startAnimation {
  animation: progress 5s normal;
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress .progress.stop {
  animation-play-state: paused;
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle {
  border: 2px solid #e1e1e1;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle {
    display: none;
  }
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle.startAnimation {
    display: block;
  }
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle.startAnimation {
  background-color: #fff;
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle .swiper-progress-bg {
  position: absolute;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle .swiper-progress-bg {
    display: none;
  }
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle .swiper-progress-bg.startAnimation {
    display: block;
  }
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle .swiper-progress-bg.startAnimation {
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  transition-duration: 0.5s;
  animation: progressAnimation 1.5s infinite;
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-circle .swiper-progress-bg.stop {
  background-color: rgba(255, 255, 255, 0);
}
.fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-num {
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-num {
    display: none;
  }
  .fullpage-wrapper .section01 .swiper-progress-container .swiper-progress-num.startAnimation {
    display: block;
  }
}
.fullpage-wrapper .section01 .swiperTxt {
  height: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}
.fullpage-wrapper .section01 .swiperTxt span {
  color: #1d9be2;
}
.fullpage-wrapper .section01 .swiperTxt ._swiper-title {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 90px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .swiperTxt ._swiper-title {
    font-size: 30px;
    line-height: 45px;
  }
}
.fullpage-wrapper .section01 .swiperTxt hr {
  width: 30%;
  margin: 30px 0;
  border: 0;
  border-top: 2px dashed #fff;
}
.fullpage-wrapper .section01 .swiperTxt ._swiper-txt {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .fullpage-wrapper .section01 .swiperTxt ._swiper-txt {
    font-size: 18px;
    line-height: 35px;
  }
  .fullpage-wrapper .section01 .swiperTxt ._swiper-txt br {
    display: none;
  }
}
.fullpage-wrapper .section01 .swiper-slide-active .center-animation {
  transform-origin: center !important;
}
.fullpage-wrapper .section01 .swiper-slide-active .swiperBot {
  animation: scaleAnimation 1.3s forwards;
}
.fullpage-wrapper .section01 .swiper-slide-active ._swiper-title {
  animation: scaleAnimation 1s forwards;
}
.fullpage-wrapper .section01 .swiper-slide-active ._swiper-txt {
  transition-delay: 1s;
  animation: scaleAnimation 2s forwards;
}
.fullpage-wrapper .section01 .swiper-slide-active ._mainBottom-title {
  animation: scaleAnimation 1s forwards;
}
.fullpage-wrapper .section01 .swiper-slide-active ._mainBottom-content {
  transition-delay: 1s;
  animation: scaleAnimation 2s forwards;
}

/*swiper arrow S*/
.swiperBot {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  cursor: pointer;
  position: relative;
  z-index: 9;
}
.swiperBot ._swiperBot-text {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: #FFFFFF;
}
.swiperBot:hover .mainArrow ._mainArw-bar {
  left: 5px;
  transition-duration: 0.5s;
}
.swiperBot:hover .mainArrow ._mainArw-cicle ._mainArw-arrow {
  width: 30px;
  transition-duration: 0.5s;
}

.mainArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainArrow ._mainArw-bar {
  position: relative;
  left: 0;
  width: 100px;
  height: 2px;
  background-color: #fff;
  transition-duration: 0.5s;
}
.mainArrow ._mainArw-cicle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #1d9be2;
  border-radius: 100px;
  position: relative;
  transition-duration: 0.5s;
}
.mainArrow ._mainArw-cicle ._mainArw-arrow {
  width: 25px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition-duration: 0.5s;
}
.mainArrow ._mainArw-cicle ._mainArw-arrow::after {
  content: "";
  display: block;
  background-color: #fff;
  height: 2px;
  width: 10px;
  position: absolute;
  top: 0%;
  right: 0%;
  border-radius: 10px;
  transform: translate(0%, calc(100% + 1px)) rotate(-40deg);
  z-index: 2;
}
.mainArrow ._mainArw-cicle ._mainArw-arrow::before {
  content: "";
  background-color: #fff;
  height: 2px;
  width: 10px;
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(0%, calc(-100% - 1px)) rotate(40deg);
  border-radius: 10px;
  transition-duration: 1s;
  z-index: 2;
}

/*swiper arrow E*/
/*nav S*/
#fp-nav {
  left: 6vw !important;
  top: 50%;
  color: #fff;
}
#fp-nav ul::after {
  content: "";
  display: block;
  background-size: 9px 30px;
  background-repeat: no-repeat;
  width: 20px !important;
  height: 35px !important;
  color: #fff;
  margin-top: 10px;
  border: 1px solid #fff;
  border-radius: 50px;
  margin-top: 23px;
}
#fp-nav::after {
  content: "";
  display: block;
  background-image: url("/public/images/arrow_1.png");
  background-position: center center;
  background-size: cover;
  position: absolute;
  bottom: 11px;
  width: 3px;
  height: 18px;
  left: 50%;
  transform: translate(-50%, -40%);
  animation: arrowAnimation 1s infinite;
}
#fp-nav::before {
  content: "scroll down";
  display: block;
  color: #fff;
  position: absolute;
  top: 228px;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}
#fp-nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#fp-nav ul li {
  margin: 10px 7px !important;
}
#fp-nav ul li:nth-child(6) {
  display: none;
}
#fp-nav ul li a span {
  margin: 0 !important;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0) !important;
  border: 1px solid #fff !important;
  width: 4px !important;
  height: 4px !important;
}
#fp-nav ul li a span::after {
  display: block;
  content: "";
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition-duration: 0.5s;
}
#fp-nav ul li a.active span {
  transform: translate(-50%, -50%);
  background: #fff !important;
  width: 8px !important;
  height: 8px !important;
}
#fp-nav ul li a.active span::after {
  display: block;
  content: "";
  animation: sparkle 1s linear infinite;
}

/*nav E*/
._privacy-content {
  display: list-item !important;
  margin-top: 20px !important;
}
._privacy-content li {
  display: list-item !important;
  list-style: decimal !important;
  margin-left: 15px !important;
  line-height: 28px !important;
  margin-bottom: 10px;
}
._privacy-content li dl {
  margin: 0;
  margin-bottom: 20px;
}
._privacy-content li dt {
  list-style: decimal !important;
  line-height: 28px !important;
}

@keyframes animationBox {
  0% {
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    top: 0%;
    height: 100%;
  }
  100% {
    background-color: rgb(255, 255, 255);
    opacity: 1;
    top: 0%;
    height: 100%;
  }
}
@keyframes animationBoxOff {
  100% {
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    top: 0%;
    height: 100%;
  }
  0% {
    background-color: rgb(255, 255, 255);
    opacity: 1;
    top: 0%;
    height: 100%;
  }
}
@keyframes anmationBg1 {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes sparkle {
  0% {
    width: 10px;
    height: 10px;
    opacity: 1;
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes scalCircle1 {
  0% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.1);
    opacity: 0;
    border: 0.5px solid #fff;
  }
}
@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes progressAnimation {
  0% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 300%;
    height: 300%;
  }
}
@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
  }
}
.fp-overflow .footer_wrap {
  position: relative !important;
  display: block !important;
}

.bottom_logo {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  background-color: #B9B9B9;
  width: 100%;
  text-align: center;
  max-width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .bottom_logo img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .bottom_logo {
    display: none;
  }
}

.swiperBot a {
  display: flex;
  align-items: center;
  width: 100%;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  width: 0px;
  height: 0px;
  transform: translate(-50%, -50%);
  border: 150vw solid rgb(0, 0, 0);
  border-radius: 50%;
  z-index: 300;
  animation: loading 1.5s 1s ease-in forwards;
}

.circle_Area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.circle_Area .circleLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 301;
  animation: logoOpaci 2s ease-in forwards;
}
.circle_Area .circle {
  border: 1px solid #fff;
  width: 25vw;
  height: 25vw;
  position: absolute;
  top: 50%;
  border-radius: 500px;
  z-index: 301;
}
.circle_Area .circle.left {
  animation: loadingLeft 1s ease-in forwards;
}
.circle_Area .circle.right {
  animation: loadingRight 1s ease-in forwards;
}

@keyframes loading {
  0% {
    width: 0vw;
    height: 0vw;
    opacity: 1;
  }
  100% {
    width: 150vw;
    height: 150vw;
    opacity: 0;
  }
}
@keyframes loadingLeft {
  0% {
    border: 1px solid #fff;
    left: 40%;
    transform: translate(-50%, -50%);
  }
  100% {
    border: 1px solid #1d9be2;
    width: 15vw;
    height: 15vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@keyframes loadingRight {
  0% {
    border: 1px solid #fff;
    right: 40%;
    transform: translate(50%, -50%);
  }
  100% {
    border: 1px solid #141d4f;
    width: 15vw;
    height: 15vw;
    right: 50%;
    transform: translate(50%, -50%);
  }
}
@keyframes logoOpaci {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 2023.02.13 new add S */
.swiperPlay {
  color: #fff;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* 2023.02.13 new add E */