.footer_wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer_wrap {
  height: 300px;
  background-color: #E6E6E6;
}
.footer_wrap ul,
.footer_wrap li,
.footer_wrap p {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer_wrap .pages_wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 70px;
}
.footer_wrap .pages_wrap .fam_select {
  outline: none;
  position: absolute;
  right: 0;
  top: 70px;
  padding: 10px;
  font-size: 14px;
  font-family: "Noto Sans KR";
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #858585;
}
@media screen and (max-width: 1198px) {
  .footer_wrap .pages_wrap .fam_select {
    display: none;
  }
}
.footer_wrap .pages_wrap a {
  width: 15%;
  display: flex;
  padding-bottom: 5px;
  align-items: flex-end;
  justify-content: center;
}
.footer_wrap .pages_wrap ul {
  width: 85%;
  align-items: flex-end;
}
.footer_wrap .pages_wrap ul li {
  padding: 0 30px;
  cursor: pointer;
  border-left: 1px solid #bababa;
}
.footer_wrap .pages_wrap ul li:first-child {
  padding-left: 0;
  border: none;
}
.footer_wrap .pages_wrap ul li .footer_title {
  margin: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 0;
  word-spacing: 2px;
  text-decoration: none;
}
.footer_wrap .pages_wrap img {
  cursor: pointer;
  height: 25px;
  width: 80px;
}
.footer_wrap .info_wrap {
  margin-top: 40px;
  padding-left: 15%;
}
.footer_wrap .info_wrap p {
  margin-bottom: 8px;
  word-spacing: 2px;
  font-size: 12px;
  color: #777777;
}
.footer_wrap .info_wrap p a {
  margin-left: 3px;
}
.footer_wrap .info_wrap p a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  .footer_wrap .pages_wrap {
    margin-top: 0 !important;
    padding-top: 30px;
    width: 80%;
    margin: 0 auto;
  }
  .footer_wrap .pages_wrap a {
    width: 100%;
    margin-bottom: 15px;
  }
  .footer_wrap .pages_wrap ul {
    width: 100%;
    justify-content: center;
  }
  .footer_wrap .pages_wrap ul li {
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer_wrap .pages_wrap ul li:first-child {
    padding-left: 30px;
  }
  .footer_wrap .info_wrap {
    margin-top: 20px;
    padding-left: 0;
    width: 80%;
    margin: 0 auto;
  }
  .footer_wrap .info_wrap p {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 691px) {
  .footer_wrap .pages_wrap {
    padding-top: 40px;
  }
  .footer_wrap .pages_wrap ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .footer_wrap .pages_wrap ul li {
    padding: 0;
    width: 100%;
  }
  .footer_wrap .pages_wrap ul li:first-child {
    padding-left: 0;
  }
  .footer_wrap .info_wrap {
    margin-top: 20px;
    padding-left: 0;
  }
  .footer_wrap .info_wrap p {
    font-size: 10px;
  }
}

.deactivate {
  display: none;
}